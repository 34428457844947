import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIEndPoint } from '../core/constants/api-endpoints';
@Injectable({
  providedIn: 'root'
})
export class CertificateService {
  api_end_point: string = '/api/v1/certifications'; //local
  constructor(private http: HttpClient) {}

  getCertification(pageNo:any, sort_by:any, sort_order:any, keyword:any, type:any) {
    let param = '';
    if (type !== 'unpublished' && sort_by === '') {
      param = '&sort_by=issued_at' + ' ' + sort_order;
    } else if (sort_by !== '') {
      param = '&sort_by=' + sort_by + ' ' + sort_order;
    }
    return this.http.get(
      APIEndPoint.ENDPOINT_URL +
        this.api_end_point +
        '?page=' +
        pageNo +
        param +
        '&keywords[]=' +
        keyword +
        '&type=' +
        type
    );
  }
  deleteCertificate(id:any) {
    return this.http.delete(
      APIEndPoint.ENDPOINT_URL + this.api_end_point + '/' + id
    );
  }

  qcCompleteForCertificate(ids: any) {
    let payload = {
      ids: ids,
    };
    return this.http.post(
      APIEndPoint.ENDPOINT_URL + this.api_end_point + '/quality_controled',
      payload
    );
  }

  deleteQcCertificate(id:any) {
    let payload = {
      ids: id,
    };
    return this.http.post(
      APIEndPoint.ENDPOINT_URL + this.api_end_point + '/remove_quality_control',
      payload
    );
  }
  deleteMultipleCertificates(idList:any) {
    let payload = {
      ids: idList,
    };
    return this.http.post(
      APIEndPoint.ENDPOINT_URL + this.api_end_point + '/destroy_certifications',
      payload
    );
  }
  getCertificate(id:any) {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL + this.api_end_point + '/' + id
    );
  }
  getSpecialties() {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v1/specialties');
  }
  getSpecialtiesV2() {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v2/specialties');
  }
  getAutocompleteTitle() {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL + '/api/v1/certifications/title_list'
    );
  }
  getSpecialties_typeWise(type:any) {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL + '/api/v1/specialties' + '?user_type=' + type
    );
  }
  saveCertification(
    cert_title:any,
    provider_name:any,
    credits:any,
    hasSelfAssessment:any,
    scoreAchieved:any,
    passingScore:any,
    totalAchievableScore:any,
    issued_at:any,
    certificate_file_remote_url:any,
    kind:any,
    specialty_ids:any,
    certificate_file:any,
    user_file_id:any,
    date_of_expiry:any,
    state_license_ids:any,
    flags:any,
    no_main_credits:any,
    isDuplicated:any,
    activity_description:any,
    activity_type:any,
    course_number:any,
    content_provider_name:any
  ) {
    let payload:any = {
      certification: {
        title: cert_title,
        provider_name: provider_name,
        credits: credits,
        has_self_assessment_exam: hasSelfAssessment,
        score_achieved: scoreAchieved,
        passing_score: passingScore,
        total_achievable_score: totalAchievableScore,
        issued_at: issued_at,
        certificate_file_remote_url: certificate_file_remote_url,
        kind: kind,
        specialty_ids: specialty_ids,
        date_of_expiry: date_of_expiry,
        state_license_ids: state_license_ids,
        flags: flags,
        no_main_credits: no_main_credits,
        draft: false,
        activity_description: activity_description,
        activity_type: activity_type,
        course_number: course_number,
        quality_control: true,
        content_provider_name: content_provider_name
      },
      user_file_id: user_file_id,
    };
    if (isDuplicated) {
      payload['create_duplicate'] = true;
    }
    return this.http.post(
      APIEndPoint.ENDPOINT_URL + this.api_end_point,
      payload
    );
  }
  updateCertification(id:any, payload:any) {
    if (payload.create_duplicate) {
      return this.http.post(
        APIEndPoint.ENDPOINT_URL + this.api_end_point,
        payload
      );
    } else {
      return this.http.put(
        APIEndPoint.ENDPOINT_URL + this.api_end_point + '/' + id,
        payload
      );
    }
  }

  parseDataForCertificate(remote_url:any) {
    return this.http.post(APIEndPoint.ENDPOINT_URL + '/api/v1/parsers', {
      remote_url: remote_url,
    });
  }
  getSpecializations(pageNo:any) {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL + '/api/v1/specializations?page=' + pageNo
    );
  }
  saveSpecializations(
    specialty_id:any,
    type:any,
    ends_at:any,
    user_file_id:any,
    flags:any,
    is_lifetimeMembership:any
  ) {
    if (flags !== undefined && flags !== null) {
      return this.http.post(
        APIEndPoint.ENDPOINT_URL + '/api/v1/specializations',
        {
          specialization: {
            specialty_id: specialty_id,
            type: type,
            ends_at: ends_at,
            flags: flags,
            lifetime_membership: is_lifetimeMembership,
          },
          user_file_id: user_file_id,
        }
      );
    } else {
      return this.http.post(
        APIEndPoint.ENDPOINT_URL + '/api/v1/specializations',
        {
          specialization: {
            specialty_id: specialty_id,
            type: type,
            ends_at: ends_at,
            lifetime_membership: is_lifetimeMembership,
          },
          user_file_id: user_file_id,
        }
      );
    }
  }
  deleteSpecializations(id:any) {
    return this.http.delete(
      APIEndPoint.ENDPOINT_URL + '/api/v1/specializations' + '/' + id
    );
  }
  getPresignedUrl(name:any) {
    return this.http.post(APIEndPoint.ENDPOINT_URL + '/api/v1/uploads', {
      name: name,
    });
  }
  getAllCertificates() {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v1/files');
  }
  getAllFiles(page:any) {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL + '/api/v1/files?page=' + page
    );
  }

  getExamType(specialty_id:any) {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL +
        '/api/v1/specialties/' +
        specialty_id +
        '/exam_types'
    );
  }
  getCourseProviders() {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v1/providers');
  }
  getStateLicenses(pageNo:any) {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL + '/api/v1/states' + '?page='
    );
  }

  getNPStateLicenses(pageNo:any) {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL + '/api/v2/states' + '?page='
    );
  }


  allFilesGet(page:any) {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL + '/api/v1/files/all?page=' + page
    );
  }

  newFilesGet(page:any) {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL + '/api/v1/files/new_files?page=' + page
    );
  }

  draftFilesGet(page:any) {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL + '/api/v1/files/draft?page=' + page
    );
  }

  getCertificateVideo(url:any) {
    return this.http.get(url);
  }

  sendInEmail(emailList:any, ids:any) {
    return this.http.post(
      APIEndPoint.ENDPOINT_URL + '/api/v1/files/send_in_email',
      {
        email: emailList,
        file_ids: ids,
      }
    );
  }

  getCreditFlags() {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL +
        '/api/v1/certifications/main_categorization_flags'
    );
  }

  getNewSecuredURL(id: any) {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL + '/api/v1/files/' + id + '/details'
    );
  }

  updateSpecializations(specializationId:any, user_file_id:any) {
    return this.http.put(
      APIEndPoint.ENDPOINT_URL + '/api/v1/specializations/' + specializationId,
      { user_file_id: user_file_id }
    );
  }

  searchAllFilesByKeyword(keyword: any, page: any, activeTab: any) {
    if (activeTab == 'new_files') {
      return this.http.get(
        APIEndPoint.ENDPOINT_URL +
          '/api/v1/files/new_files?page=' +
          page +
          '&title=' +
          keyword
      );
    } else if (activeTab == 'draft_files') {
      return this.http.get(
        APIEndPoint.ENDPOINT_URL +
          '/api/v1/files/draft?page=' +
          page +
          '&title=' +
          keyword
      );
    } else {
      return this.http.get(
        APIEndPoint.ENDPOINT_URL +
          '/api/v1/files/all?page=' +
          page +
          '&title=' +
          keyword
      );
    }
  }

  reportCertificationIssue(certId:any, message:any) {
    return this.http.post(
      APIEndPoint.ENDPOINT_URL + '/api/v1/admins/report_info',
      {
        type: 'Certification',
        id: certId,
        message: message,
      }
    );
  }

  updateCeritificationFile(certId:any, user_file_id:any) {
    return this.http.put(
      APIEndPoint.ENDPOINT_URL + '/api/v1/certifications/' + certId,
      { user_file_id: user_file_id }
    );
  }

  getCSRStateList() {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL +
        '/api/v1/controlled_substance_registrations/enabled_states'
    );
  }

  getCSREnabledStates() {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL +
        '/api/v1/controlled_substance_registrations/enabled_states'
    );
  }

  getExistingCSRStates() {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL +
        '/api/v1/controlled_substance_registrations/existing_csr'
    );
  }

  getDEAStateList() {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL + '/api/v1/dea/enabled_states'
    );
  }

  getCEList(pageNo:any) {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL + this.api_end_point + '?page=' + pageNo
    );
  }

  saveCECertificate(params:any) {
    return this.http.post(
      APIEndPoint.ENDPOINT_URL + this.api_end_point,
      params
    );
  }

  updateContinuingEducation(id:any, params:any) {
    return this.http.put(
      APIEndPoint.ENDPOINT_URL + this.api_end_point + '/' + id,
      params
    );
  }

  //MD & DO user API
  getMdDoSpecialization(pageNo:any) {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL + '/api/v2/specializations?page=' + pageNo
    );
  }

  getPracticingSpecialty() {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL + '/api/v1/practicing_specialty'
    );
  }

  getIssuingBoard() {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL + '/api/v1/issuing_authority'
    );
  }

  getBoardSpecialty(id:any) {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL + '/api/v2/specialties?issuing_board_id=' + id
    );
  }

  saveV2Certificate(params:any) {
    return this.http.post(
      APIEndPoint.ENDPOINT_URL + '/api/v2/specializations',
      params
    );
  }

  //for practicing speciality
  savePracticingSpecialty(param:any) {
    return this.http.post(
      APIEndPoint.ENDPOINT_URL + '/api/v1/practicing_specialty',
      param
    );
  }

  getMDDOCategorizedFlags() {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v2/states/flags');
  }

  getCategorizationDropdownList(date: any){
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v1/categorization?issued_at='+ date);
  }

  getCategorizationById(id: any) {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL + "/api/v1/categorization" + "/" + id
    );
  }
}
